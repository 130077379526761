import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import Layout from '../../components/layout'

import scarpe from '../../images/scarpe.jpg'

import '../../styles/common-styles.css'
import '../../styles/music-styles.css'
import '../../styles/layout-styles.css'
import '../../styles/album-styles.css'

const ScarpePage = () => {
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  return (
    <Layout>
      <div className='pageTitle'>Scarpe!</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      {!isMobile && (
        <div className='albumInfoWrapper'>
          <img className='albumInfoImage' src={scarpe} alt='' />
          <div className='albumInfoRight'>
            <div className='albumInfoNotes text-gray-700'>
              <p className='my-4'>
                "Stiletto Brass Quintet is excited to announce the upcoming
                release of our new cd Scarpe! on CD Baby. Features works by Drew
                Bonner and Andre Lafosse amongst others. CDs will be available
                on CD Baby and Amazon or download from ITunes or Apple Music."
                --Stiletto Brass Quintet
              </p>
              <p className='my-4'>
                <a
                  href='https://www.amazon.com/Scarpe-Stiletto-Brass-Quintet/dp/B08KHMDJ61'
                  target='_blank'
                  rel='noreferrer'
                >
                  Scarpe! can be purchased here
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='mobileAlbumInfoWrapper'>
          <img className='mobileAlbumInfoImage' src={scarpe} alt='' />
          <div className='content text-gray-700'>
            <p className='my-4'>
              "Stiletto Brass Quintet is excited to announce the upcoming
              release of our new cd Scarpe! on CD Baby. Features works by Drew
              Bonner and Andre Lafosse amongst others. CDs will be available on
              CD Baby and Amazon or download from ITunes or Apple Music."
              --Stiletto Brass Quintet
            </p>
            <p className='my-4'>
              <a
                href='https://www.amazon.com/Scarpe-Stiletto-Brass-Quintet/dp/B08KHMDJ61'
                target='_blank'
                rel='noreferrer'
              >
                Scarpe! can be purchased here
              </a>
            </p>
          </div>
        </div>
      )}
      <div
        style={{ marginLeft: '15%', marginTop: '35px', marginBottom: '35px' }}
      >
        <Link to='/music'>←Back to Discography</Link>
      </div>
    </Layout>
  )
}

export default ScarpePage
